import type { AgreementTileData } from './components/tiles/AgreementTile/tileType';
import type { CampaignTileData } from './components/tiles/CampaignTile/tileType';
import type { EventTileData } from './components/tiles/EventTile/tileType';
import type { OfferTileData } from './components/tiles/OfferTile/tileType';
import type { OfficeTileData } from './components/tiles/OfficeTile/tileType';
import type { PersonTileData } from './components/tiles/PersonTile/tileType';
import type { PostTileData } from './components/tiles/PostTile/tileType';
import type { ProductTileData } from './components/tiles/ProductTile/tileType';
import type { ServiceTileData } from './components/tiles/ServiceTile/tileType';
import type { BasicImageData } from '~~/src/components/components/core/basicImage/type';

export enum TileTypeHandle {
  EVENT = 'event',
  OFFER = 'offer',
  OFFICE = 'office',
  PERSON = 'person',
  POST = 'post',
  SERVICE = 'service',
  AGREEMENT = 'agreement',
  PRODUCT = 'product',
  CAMPAIGN = 'campaign',
}

export type TileData =
  | EventTileData
  | OfferTileData
  | OfficeTileData
  | PersonTileData
  | PostTileData
  | ServiceTileData
  | AgreementTileData
  | ProductTileData
  | CampaignTileData;

export interface BaseTileData<T = TileTypeHandle> {
  id: string;
  typeHandle: T;
  uri?: string;
  image?: BasicImageData;
  title?: string;
}
